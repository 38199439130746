.rapport_facture{
    width: 100%;
    .rapport_h2{
        font-size: 1.2rem;
        font-weight: 500;
        text-align: center;
        margin-bottom: 15px;
        border-bottom: 2px solid #e8e8e8;
        padding-bottom: 10px;
    }
    .hidden-column {
        display: none;
      }
    .rapport_row_excel{
        display: flex;
        align-items: center;
        gap: 10px;
        .export-excel{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            background-color: #e0f3e0;
            border-radius: 8px;
            cursor: pointer;
            transition: all 0.3s ease;
            .excel-icon{
                font-size: 18px;
                color: #1d6f42;
            }

            &:hover {
                background-color: #c3e6c3;
                transform: scale(1.1);
              }
        }
        .export-pdf {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            background-color: #f8d7da;  // Couleur de fond rouge clair
            border-radius: 8px;
            cursor: pointer;
            transition: all 0.3s ease;
          
            .pdf-icon {
              font-size: 18px;
              color: #c82333;  // Rouge vif pour l'icône
            }
          
            &:hover {
              background-color: #f5c6cb; // Couleur de fond au survol
              transform: scale(1.1); // Effet d'agrandissement au survol
            }
          }
          
    }
}